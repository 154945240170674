import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import { OrganizationFragmentDoc } from '../../api/fragments/Organization.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OrganizationQuery = {
  organization: {
    id: number;
    name: string;
    suspended: boolean;
    legalEntity: string;
    supportEmail: string;
    termsAndConditions: string | null;
    privacyPolicy: string | null;
    plan: { type: Types.OrganizationPlanType };
    whiteLabel: {
      backgroundColor: string | null;
      logoUrl: string | null;
      brandmarkUrl: string | null;
      primaryColor: string | null;
      profitGoal: number;
    };
    domains: {
      appUrls: Array<{ name: string; isDefault: boolean }>;
      farmerUrls: Array<{ name: string; isDefault: boolean }>;
      feedUrls: Array<{ name: string; isDefault: boolean }>;
      partnerUrls: Array<{ name: string; isDefault: boolean }>;
      postbackUrls: Array<{ name: string; isDefault: boolean }>;
      supersetUrls: Array<{ name: string; isDefault: boolean }>;
      surveyorUrls: Array<{ name: string; isDefault: boolean }>;
      trackingUrls: Array<{ name: string; isDefault: boolean }>;
      turniloUrls: Array<{ name: string; isDefault: boolean }>;
      ratelimiterUrls: Array<{ name: string; isDefault: boolean }>;
    };
  };
};

export const OrganizationDocument = gql`
  query Organization {
    organization {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>;
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
