import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import Loading from '../Loading';
import Maintenance from '../Maintenance';
import Suspended from '../Suspended';

import {
  OrganizationQuery,
  useOrganizationQuery,
} from './Organization.generated';

type Organization = OrganizationQuery['organization'];

export const OrganizationContext = createContext<Organization | undefined>(
  undefined,
);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);

  if (!organization) {
    throw new Error('No organization');
  }

  return organization;
};

export const useOrganizationDomains = (): Organization['domains'] => {
  const { domains } = useOrganization();

  if (!domains) {
    throw new Error('No organization domains');
  }

  return domains;
};

export const useDefaultOrganizationDomains = (): Record<
  string,
  string | undefined
> => {
  const domains = useOrganizationDomains();

  return Object.fromEntries(
    Object.entries(domains).map(([key, value]) => {
      if (Array.isArray(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const domain = value.find(
          (domain: { isDefault: boolean }) => domain.isDefault,
        )?.name;
        const url = domain ? `https://${domain}` : undefined;

        return [key.slice(0, -1), url];
      }

      return [key, value];
    }),
  );
};

export const useOrganizationSettings = (): Organization['whiteLabel'] => {
  const { whiteLabel: settings } = useOrganization();

  if (!settings) {
    throw new Error('No organization settings');
  }

  return settings;
};

const OrganizationProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data, error, loading } = useOrganizationQuery({
    fetchPolicy: 'cache-first',
  });

  const organization = useMemo(() => data?.organization, [data?.organization]);

  if (organization?.suspended) {
    return <Suspended />;
  }

  if (error) {
    return <Maintenance />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.displayName = 'OrganizationProvider';

export default OrganizationProvider;
