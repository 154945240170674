import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
export type DomainFragment = { name: string; isDefault: boolean };

export const DomainFragmentDoc = gql`
  fragment Domain on Domain {
    name
    isDefault
  }
`;
