import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import { AuthFragmentDoc } from './Auth.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshTokenMutationVariables = Types.Exact<{
  input: Types.AuthRefreshTokenInput;
}>;

export type RefreshTokenMutation = {
  refreshToken: {
    authChallenge: Types.AuthChallenge;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      permissions: Array<Types.Permission>;
      roles: Array<Types.Role>;
      status: Types.UserStatus;
    } | null;
    token: {
      accessToken: string;
      expiresIn: number;
      refreshToken: string;
    } | null;
  };
};

export const RefreshTokenDocument = gql`
  mutation RefreshToken($input: AuthRefreshTokenInput!) {
    refreshToken(input: $input) {
      ...Auth
    }
  }
  ${AuthFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
