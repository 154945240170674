import * as Types from '../../graphql.generated';

import { gql } from '@apollo/client';
import { DomainFragmentDoc } from './Domain.fragment.generated';
export type OrganizationFragment = {
  id: number;
  name: string;
  suspended: boolean;
  legalEntity: string;
  supportEmail: string;
  termsAndConditions: string | null;
  privacyPolicy: string | null;
  plan: { type: Types.OrganizationPlanType };
  whiteLabel: {
    backgroundColor: string | null;
    logoUrl: string | null;
    brandmarkUrl: string | null;
    primaryColor: string | null;
    profitGoal: number;
  };
  domains: {
    appUrls: Array<{ name: string; isDefault: boolean }>;
    farmerUrls: Array<{ name: string; isDefault: boolean }>;
    feedUrls: Array<{ name: string; isDefault: boolean }>;
    partnerUrls: Array<{ name: string; isDefault: boolean }>;
    postbackUrls: Array<{ name: string; isDefault: boolean }>;
    supersetUrls: Array<{ name: string; isDefault: boolean }>;
    surveyorUrls: Array<{ name: string; isDefault: boolean }>;
    trackingUrls: Array<{ name: string; isDefault: boolean }>;
    turniloUrls: Array<{ name: string; isDefault: boolean }>;
    ratelimiterUrls: Array<{ name: string; isDefault: boolean }>;
  };
};

export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    plan {
      type
    }
    suspended
    legalEntity
    supportEmail
    whiteLabel {
      backgroundColor
      logoUrl
      brandmarkUrl
      primaryColor
      profitGoal
    }
    termsAndConditions
    privacyPolicy
    plan {
      type
    }
    domains {
      appUrls {
        ...Domain
      }
      farmerUrls {
        ...Domain
      }
      feedUrls {
        ...Domain
      }
      partnerUrls {
        ...Domain
      }
      postbackUrls {
        ...Domain
      }
      supersetUrls {
        ...Domain
      }
      surveyorUrls {
        ...Domain
      }
      trackingUrls {
        ...Domain
      }
      turniloUrls {
        ...Domain
      }
      ratelimiterUrls {
        ...Domain
      }
    }
  }
  ${DomainFragmentDoc}
`;
